<template>
  <div>
    <a-modal
      width="1220px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑三方卡信息"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息， 周期容量设置-->
            <a-col :span="12">
              <a-form-item
                label="ICCID"
              >
                {{ data.iccid }}
              </a-form-item>

              <a-form-item
                label="MSISDN"
              >
                <a-input
                  v-decorator="['msisdn', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 20, message: '最多20个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="IMSI"
              >
                <a-input
                  v-decorator="['imsi', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 20, message: '最多20个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="运营商种类"
              >
                <a-select
                  v-decorator="['carrier_type']"
                  allow-clear
                  disabled
                >
                  <a-select-option
                    v-for="option in carrierTypeOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="供应商">
                <a-select
                  v-decorator="['supplier']"
                  :loading="loadingSupplierOptions"
                  @focus="fetchSupplierOptions"
                  allow-clear
                  disabled
                >
                  <a-select-option
                    v-for="option in supplierOptions"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item
                label="采购套餐"
              >
                <a-input
                  v-decorator="['package', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 50, message: '最多50个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="采购周期"
              >
                <a-input
                  v-decorator="['cycles', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 20, message: '最多20个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="采购单价"
              >
                <a-input
                  v-decorator="['price', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 20, message: '最多20个字符' },
                    ]
                  }]"
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                label="采购时间"
              >
                <a-date-picker
                  style="width: 100%"
                  v-decorator="['order_date', {
                    initialValue: this.$moment()
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="沉默期"
              >
                <a-input
                  v-decorator="['silent_period', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 20, message: '最多20个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="到期时间"
              >
                <a-date-picker
                  style="width: 100%"
                  v-decorator="['end_date', {
                    initialValue: this.$moment()
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="项目"
              >
                <a-input
                  v-decorator="['project', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 50, message: '最多50个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="分组"
              >
                <a-input
                  v-decorator="['group_name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="设备名称"
              >
                <a-input
                  v-decorator="['device_name', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="设备序列号"
              >
                <a-input
                  v-decorator="['device_sn', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item
                label="备注"
              >
                <a-textarea
                  v-decorator="['remark', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { max: 200, message: '最多200个字符' },
                    ]
                  }]"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { formatDate } from '@/utils/time'
import { findCarrierTypeOptions } from '@/api/common_options'
import { findSuppliersOptions, findSimCardBaseInfo, editSimCards } from '@/api/oc_sim_card'

export default {
  name: 'EditSimCard',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'edit_sim_card' }),
      carrierTypeOptions: findCarrierTypeOptions(),
      supplierOptions: [],
      submitting: false,
      spinning: false,
      data: {},
      loadingSupplierOptions: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    // 加载供应商选项
    fetchSupplierOptions() {
      if (this.supplierOptions.length === 0) {
        this.loadingSupplierOptions = true
        findSuppliersOptions({ agent_id: this.agentId }).then(res => {
          if (res.code === 0) {
            this.supplierOptions = res.data
            this.loadingSupplierOptions = false
          }
        })
      }
    },

    fetchFormData() {
      this.spinning = true
      findSimCardBaseInfo(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data

          this.form.setFieldsValue({
            msisdn: this.data.msisdn,
            imsi: this.data.imsi,
            carrier_type: this.data.carrier_type,
            supplier: this.data.supplier,
            package: this.data.package,
            cycles: this.data.cycles,
            price: this.data.price,
            silent_period: this.data.silent_period,
            order_date: this.$moment(this.data.order_date, 'YYYY-MM-DD'),
            end_date: this.$moment(this.data.end_date, 'YYYY-MM-DD'),
            project: this.data.project,
            group_name: this.data.group_name,
            device_name: this.data.device_name,
            device_sn: this.data.device_sn,
            remark: this.data.remark
          })
        }

        this.spinning = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = {
            ...values,
            order_date: formatDate(values.order_date),
            end_date: formatDate(values.end_date)
          }

          editSimCards(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.item-title {
  color: @primary-color;
}
</style>
